<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>Audiometry</h1>
            <p>
              Pemeriksaan untuk menentukan jenis dan derajat ketulian (gangguan
              dengar).
            </p>
            <br />

            <p>
              Dengan pemeriksaan ini dapat ditentukan jenis ketulian apakah :
            </p>

            <p>- Tuli Konduktif</p>
            <p>- Tuli Saraf (Sensorineural)</p>
            <br />
            <p>Serta derajat ketulian.</p>
            <br />
            <p>
              Audiometer adalah peralatan elektronik untuk menguji pendengaran.
              Audiometer diperlukan untuk mengukur ketajaman pendengaran:
            </p>

            <p>- digunakan untuk mengukur ambang pendengaran</p>
            <p>- mengindikasikan kehilangan pendengaran</p>
            <p>- pembacaan dapat dilakukan secara manual atau otomatis</p>
            <p>
              - mencatat kemampuan pendengaran setiap telinga pada deret
              frekuensi yang berbeda
            </p>

            <p>
              - menghasilkan audiogram (grafik ambang pendengaran untuk
              masing-masing telinga pada suatu rentang frekuensi)
            </p>

            <p>
              - pengujian perlu dilakukan di dalam ruangan kedap bunyi namun di
              ruang yang heningpun hasilnya memuaskan
            </p>

            <p>
              - berbiaya sedang namun dibutuhkan hanya jika kebisingan merupakan
              masalah/kejadian yang terus-menerus, atau selain itu dapat
              menggunakan fasilitas di rumah sakit
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/audio.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "Audiometry RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
